
<template>
  <div class="app_con">
    <app-list
      :remote="remote"
      :props="props"
      :searchQuery="searchQuery"
      :searchControl="searchControl"
      :apiName="apiName"
      :editTitle="'会员卡'"
      :buttonShow="false"
      isInline
      :isSelectShow="false"
    >
      <div slot="toolSub" selecTionArr="">
        <el-button
          type="primary"
          icon="el-icon-plus"
          @click="dialogVisible = true"
          >实体卡充值</el-button
        >
        <el-button type="warning" @click="exportExcel" icon="el-icon-upload2"
          >导出</el-button
        >
      </div>
      <div class="search" slot="tool">
        <el-input
          clearable
          style="width: 317px"
          placeholder="输入姓名"
          v-model="searchQuery.name"
          class="searchInput"
        >
          <template slot="prepend">会员姓名</template>
        </el-input>
        <el-input
          clearable
          style="width: 317px"
          placeholder="输入身份证号"
          v-model="searchQuery.idCard"
          class="searchInput"
        >
          <template slot="prepend">身份证号</template>
        </el-input>
        <div class="searchInput el-input-group">
          <div class="el-input-group__prepend">会员类型</div>
          <el-select
            clearable
            v-model="searchQuery.cardType"
            placeholder="选择卡类型"
          >
            <el-option
              v-for="item in statusList"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </div>
        <div class="searchInput el-input-group">
          <div class="el-input-group__prepend">起始日期</div>
          <el-date-picker
            v-model="searchQuery.startDate"
            type="date"
            placeholder="选择日期"
            value-format="yyyy-MM-dd"
          ></el-date-picker>
        </div>
        <div class="searchInput el-input-group">
          <div class="el-input-group__prepend">结束日期</div>
          <el-date-picker
            v-model="searchQuery.endDate"
            type="date"
            placeholder="选择日期"
            value-format="yyyy-MM-dd"
          ></el-date-picker>
        </div>
      </div>
    </app-list>
    <el-dialog
      :title="`会员卡充值`"
      center
      :visible.sync="dialogVisible"
      width="600px"
      :close-on-click-modal="false"
    >
      <el-form label-width="80px" class="inlineForm">
        <el-form-item label="会员卡号" class="fullWidth">
          <el-input
            :style="{ width: '260px', 'margin-right': '10px' }"
            v-model="propsFormData.cardNo"
            placeholder="填写会员卡号"
          ></el-input>
          <el-button type="warning" icon="el-icon-search" @click="getInfo"
            >查询</el-button
          >
        </el-form-item>
        <el-form-item label="会员姓名" class="fullWidth">
          <el-input
            :style="{ width: '360px', 'margin-right': '10px' }"
            :value="vipInfo.name"
            placeholder=""
            readonly
          ></el-input>
        </el-form-item>
        <el-form-item label="身份证号" class="fullWidth">
          <el-input
            :style="{ width: '360px', 'margin-right': '10px' }"
            :value="vipInfo.idCard"
            placeholder=""
            readonly
          ></el-input>
        </el-form-item>
        <el-form-item label="会员状态" class="fullWidth">
          <el-input
            :style="{ width: '360px', 'margin-right': '10px' }"
            :value="vipStatus(vipInfo.status)"
            placeholder=""
            readonly
          ></el-input>
        </el-form-item>
        <el-form-item label="充值金额" class="fullWidth">
          <el-input
            :style="{ width: '360px', 'margin-right': '10px' }"
            v-model="vipInfo.money"
            placeholder=""
          ></el-input>
          <!-- <el-button type="warning" icon="el-icon-search" @click="patchVip"
            >查询</el-button
          > -->
        </el-form-item>
        <el-form-item label="赠送金额" class="fullWidth">
          <el-input
            :style="{ width: '360px', 'margin-right': '10px' }"
            v-model="vipInfo.giftMoney"
            placeholder=""
          ></el-input>
          <!-- <el-button type="warning" icon="el-icon-search" @click="patchVip"
            >查询</el-button
          > -->
        </el-form-item>
        <el-form-item label="支付方式" class="fullWidth">
          <el-select
            clearable
            v-model="vipInfo.payType"
            placeholder="选择卡类型"
          >
            <el-option
              v-for="item in payTypeList"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <div class="flex justify-center align-center">
          <el-button @click="dialogVisible = false">取消</el-button>
          <el-button :loading="loading" type="primary" @click="handelAdd()"
            >提交</el-button
          >
        </div>
      </el-form>
    </el-dialog>
  </div>
</template>

<script>
import api from "@/api";

export default {
  data() {
    return {
      remote: api.common,
      apiName: "/vip/recharge",
      searchQuery: { name: "", refurbishNum: 0 },
      dialogVisible: false,
      loading: false,
      searchControl: {
        firstSoltSpan: [0, 0, 0, 0],
        secondSoltSpan: [6, 6, 6, 6],
        thirdSoltSpan: [18, 18, 18, 18],
      },
      props: [
        {
          label: "会员姓名",
          prop: "name",
          align: "center",
        },
        {
          label: "身份证号",
          prop: "idCard",
          align: "center",
        },
        {
          label: "会员卡号",
          prop: "cardNo",
          align: "center",
        },
        // {
        //   label: "卡片状态",
        //   prop: "status",
        //   align: "center",
        //   type: "tag",
        //   formatter: function (row) {
        //     const statusMap = { 0: "正常", 1: "作废", 2: "丢失", 3: "退卡" };
        //     return statusMap[row.status];
        //   },
        // },
        {
          label: "充值余额",
          prop: "money",
          align: "center",
        },
        {
          label: "赠送余额",
          prop: "giftMoney",
          align: "center",
        },

        {
          label: "充值日期",
          prop: "createTime",
          align: "center",
        },
      ],
      statusList: [
        {
          label: "实体",
          value: 0,
        },
        {
          label: "虚拟",
          value: 1,
        },
      ],
      // 、0现金，1支付宝，2微信，3银行卡，4聚合支付
      payTypeList: [
        {
          label: "现金",
          value: 0,
        },
        {
          label: "支付宝",
          value: 1,
        },
        {
          label: "微信",
          value: 2,
        },
        {
          label: "银行卡",
          value: 3,
        },
        {
          label: "聚合支付",
          value: 4,
        },
      ],
      propsFormData: {
        idCard: "",
      },
      vipInfo: {
        name: "",
        cardNo: "",
        status: "",
        money: "",
        giftMoney: 0,
        payType: "",
      },
    };
  },
  computed: {
    vipStatus() {
      return function (status) {
        console.log(status);
        const statusMap = { 0: "正常", 1: "作废", 2: "丢失", 3: "退卡" };
        return statusMap[status];
      };
    },
  },
  created() {},
  methods: {
    // 获取会员信息
    async getInfo() {
      if (this.$test.isEmpty(this.propsFormData.cardNo)) {
        this.$message({ message: "请输入会员卡号码", type: "error" });
        return;
      }
      let result = await api.common.detail({
        apiName: "/vip/user",
        cardNo: this.propsFormData.cardNo,
      });
      Object.assign(this.vipInfo, result.data);
      // this.vipInfo = result.data;
    },
    // 添加补卡
    async handelAdd() {
      const { vipInfo, apiName } = this;
      console.log(vipInfo);
      const data = {
        cardNo: vipInfo.cardNo,
        money: vipInfo.money,
        giftMoney: vipInfo.giftMoney,
        payType: vipInfo.payType,
      };
      // 校验参数
      if (this.$test.isEmpty(data.cardNo)) {
        this.$message({ message: "请输入会员卡号", type: "error" });
        return;
      }
      if (this.$test.isEmpty(data.money)) {
        this.$message({ message: "请输入充值金额", type: "error" });
        return;
      }
      if (this.$test.isEmpty(data.payType)) {
        this.$message({ message: "请选择支付方式", type: "error" });
        return;
      }
      // 按钮加载
      this.loading = true;
      // 请求数据
      let result = await api.common.create({
        apiName: apiName,
        ...data,
      });
      // 请求结果 ->捕获异常
      if (result.code == 200) {
        this.$message({ message: result.message, type: "success" });
      } else {
        this.$message({ message: result.message, type: "error" });
      }
      this.searchQuery.refurbishNum++;
      this.dialogVisible = false; //模态框关闭
      this.loading = false; //按钮加载停止转动
    },
    // 导出
    async exportExcel() {
      const { searchQuery } = this;
      await api.common.download({
        apiName: "/vip/recharge/exportExcel",
        ...searchQuery,
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.search {
  display: flex;
}
.el-select {
  /deep/ .el-input__inner {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
}
</style>
